<template>
  <div>
    <h5>Ввод данных о контактном лице и организации</h5>
    <p>Нам нужна эта информация для связи с вами</p>
    <div class="dflex space-between-v">
      <b-row v-for="(item, index) in form" :key="item.id" align-h="center">
        <b-col>
          <b-form-group
            :label="item.name"
            :label-for="item.id"
            label-cols-sm="4"
            content-cols-lg="8"
            label-align="left"
            label-align-sm="right"
          >
            <BaseTextInput v-if="index < 3" v-model="item.value" :id="item.id" required />
            <InputPhone
              v-else-if="index == 3"
              v-model="item.value"
              :disabled="isWaitingToConfirm"
              :id="item.id"
              required
            />
            <BaseTextInput
              v-if="index == 4"
              v-model="item.value"
              @input="getOrganizationNameByInn(item.value)"
              :id="item.id"
              maxlength="12"
              required
            />
            <BaseTextInput
              v-else-if="index == 5"
              v-model="item.value"
              :id="item.key"
              placeholder="Наименование огранизации определится по ИНН"
              readonly
              required
            />
            <!-- Errors messages start -->
            <slot>
              <div v-if="isEmptyInput">
                <div v-if="item.value == '' && index != 3" class="error">
                  {{ item.errorText }}
                </div>
                <div
                  v-else-if="index === 3 && (item.value == '' || phoneNumber.length < 11)"
                  class="error"
                >
                  {{ item.errorText }}
                </div>
                <div
                  v-else-if="
                    (index === 4 && form[5].value === '') || (index === 4 && item.value.length > 12)
                  "
                  class="error"
                >
                  {{ item.errorText }}
                </div>
                <div v-if="index === 3 && !isPhoneConfirmed" class="error">
                  Подтвердите Контактный телефон
                </div>
              </div>
            </slot>
            <!-- Errors messages end-->
            <div v-if="index == 3">
              <b-row :class="[isPhoneConfirmed ? 'success' : '', 'error']" class="phone-message">
                <b-col>
                  <slot v-if="isWaitingToConfirm">
                    <br />
                    Для подтверждения вашего номера телефона (+{{ phoneNumber }}), позвоните с него
                    на номер 7(495)449-30-31. Мы не будем поднимать трубку.
                  </slot>
                  <slot v-else-if="isServerError">
                    <br />
                    Произошла внутренняя ошибка сервера. Попробуйте позже.
                  </slot>
                  <slot v-else-if="isTimeOver">
                    <br />
                    Время ожидания звонка истекло.
                  </slot>
                  <slot v-else-if="isPhoneConfirmed">
                    <br />
                    Номер телефона подтвержден
                  </slot>
                </b-col>
              </b-row>
              <b-row class="confirm-phone__btn_wrap">
                <b-col cols="12" class="confirm-phone__btn dflex">
                  <BaseButton
                    :disabled="phoneNumber.length < 11 || isWaitingToConfirm"
                    @click.stop.prevent="confirmPhoneNumber"
                  >
                    <template #text>
                      <slot>Подтвердить</slot>
                    </template>
                  </BaseButton>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col align-h="center">
          <b-form-checkbox
            @change="applyTerms"
            id="checkbox-1"
            name="checkbox-1"
            value="accepted"
            unchecked-value="not_accepted"
            class="reg-terms"
          >
            Я даю
            <a
              target="_blank"
              href="http://www.stranzit.ru/upload/medialibrary/4b4/Polozhenie_o_personalnih.pdf"
              > согласие на обработку персональных данных </a
            >
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <br />
    <footer>
      <b-row align-h="center">
        <BaseButton :disabled="!isApply" @click.stop.prevent="showNextStep()">
          <template #text>
            <slot>Далее</slot>
          </template>
        </BaseButton>
      </b-row>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import InputPhone from '@/components/shared/inputs/InputPhone.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { confirmCustomerNumber, getOrganization } from '@/api/registration';
import { setPostOptions } from '@/api/common';
import { checkForEmptyKeys } from '@/additional_helpers/helpers';

export default {
  data() {
    return {
      form: [
        {
          id: 'FirstName',
          name: 'Имя',
          value: '',
          errorText: 'Введите Имя',
          isError: false
        },
        {
          id: 'LastName',
          name: 'Фамилия',
          value: '',
          errorText: 'Введите Фамилию',
          isError: false
        },
        {
          id: 'Email',
          name: 'E-mail',
          value: '',
          errorText: 'Введите E-mail',
          isError: false
        },
        {
          id: 'PhoneNumber',
          name: 'Контактный телефон',
          value: '',
          errorText: 'Введите Контактный телефон',
          isError: false
        },
        {
          id: 'Inn',
          name: 'ИНН организации',
          value: '',
          errorText: 'ИНН не найден',
          isError: false
        },
        {
          id: 'CompanyName',
          name: 'Название организации',
          value: '',
          isError: false
        }
      ],
      isActive: false,
      isWaitingToConfirm: false,
      isServerError: false,
      isTimeOver: false,
      isPhoneConfirmed: false,
      isApply: false,
      isEmptyInput: false
    };
  },

  components: {
    BaseButton,
    InputPhone,
    BaseTextInput
  },

  computed: {
    ...mapGetters({
      customerData: 'registration/customerData'
    }),

    // Transform phoneNumber from +7 (999) 999-99-99 to 79999999999
    phoneNumber() {
      return this.form[3].value.replace(/\D/g, '').substring(0, 11);
    },

    formValidation() {
      const check = () => {
        const isErrorTrue = checkForEmptyKeys(this.form);

        if (isErrorTrue) {
          this.isEmptyInput = true;
        } else if (
          (isErrorTrue && !this.isPhoneConfirmed)
          || (isErrorTrue && this.isPhoneConfirmed && this.form[3].length === 11)
        ) {
          this.isEmptyInput = true;
        } else if (!isErrorTrue && !this.isPhoneConfirmed) {
          this.isEmptyInput = true;
          this.form[3].isError = true;
        } else {
          if (this.form[3].isError) {
            this.form[3].isError = false;
          }
          this.isEmptyInput = false;
          return true;
        }
        return false;
      };
      return check();
    }
  },

  methods: {
    ...mapActions({
      createCustomerData: 'registration/createCustomerData',
      showBasePage: 'registration/showBasePage'
    }),

    confirmPhoneNumber() {
      const body = { Phone: this.phoneNumber, Id: 0 };
      const phoneConfirmData = JSON.stringify(body);

      confirmCustomerNumber(setPostOptions(phoneConfirmData))
        .then((result) => {
          let timerTicks = 0;
          this.isWaitingToConfirm = true;

          if (this.isPhoneConfirmed) this.isPhoneConfirmed = false;

          // eslint-disable-next-line consistent-return
          const requestInterval = setInterval(() => {
            let id = null;

            try {
              if (typeof result.Id !== 'undefined' && result.Id !== undefined) {
                id = result.Id;
                if (result.Status === 'PING') {
                  clearInterval(requestInterval);
                } else if (result.Status === 'WAITING_CALL') {
                  this.isActive = false;
                  timerTicks += 1;
                }
                if (timerTicks > result.Tics) {
                  this.isWaitingToConfirm = false;
                  this.isActive = true;
                  this.isTimeOver = true;
                  timerTicks = 0;
                  clearInterval(requestInterval);
                }
              } else {
                clearInterval(requestInterval);
              }
            } catch (e) {
              console.error(e);
              clearInterval(requestInterval);
              timerTicks = 0;
              this.isServerError = true;
              return false;
            }

            const requestStatus = JSON.stringify({
              Phone: this.form[3].value,
              Id: id
            });

            confirmCustomerNumber(setPostOptions(requestStatus))
              .then((results) => {
                if (results.Status === 'CONFIRMED_CALL') {
                  this.isWaitingToConfirm = false;
                  if (this.isTimeOver) this.isTimeOver = false;
                  this.isPhoneConfirmed = true;
                  clearInterval(requestInterval);
                }
              })
              .catch((e) => console.error(e));
          }, 2000);
        })
        .catch((e) => console.error(e));
    },

    applyTerms() {
      this.isApply = !this.isApply;
    },

    getOrganizationNameByInn(value) {
      if (value.length >= 10) {
        getOrganization(value)
          .then((result) => {
            if (result) {
              this.form[5].value = result;
            } else {
              this.isEmptyInput = true;
            }
          })
          .catch((e) => console.error(e));
      }
      if (value.length < 10) this.form[5].value = '';
    },

    showNextStep() {
      if (this.formValidation) {
        this.createCustomerData(this.form);
        this.showBasePage(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.phone-message {
  text-align: left;
}

.reg-terms {
  font-size: 0.875rem;
}

.confirm-phone__btn_wrap {
  padding-top: 1rem;
}

.confirm-phone__btn {
  justify-content: center;
}

@media (min-width: 576px) {
  .confirm-phone__btn {
    justify-content: flex-end;
  }
}
</style>
