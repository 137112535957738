import URL from '../basUrl';

const NUMBERS_REGISTRATION = `${URL.NUMBERS_REGISTRATION}?city=Москва`;
const CONFIRM_NUMBER = `${URL.CONFIRM_NUMBER}`;

export const COMPANIES_REQUEST = () => {
  const options = {
    method: 'GET',
    redirect: 'follow'
  };
  return options;
};

// ContactData, Login, Password, PasswordConfirm, Inn, Numbers
export const getNumbers = async () => {
  const response = await fetch(NUMBERS_REGISTRATION);
  return response.json();
};

export const confirmCustomerNumber = async (requestOptions) => {
  const response = await fetch(CONFIRM_NUMBER, requestOptions);
  return response.json();
};

// eslint-disable-next-line consistent-return
export const getOrganization = async (innValue) => {
  const response = await fetch(`${URL.DATA_ORGANIZATIONS}?inn=${innValue}`, COMPANIES_REQUEST);
  if (response.ok) {
    return response.text();
    // eslint-disable-next-line no-else-return
  } else {
    return false;
  }
};

export const createCustomer = async (requestOptions) => {
  const response = await fetch(URL.REGISTRATION, requestOptions);
  if (response.length > 0) {
    return response.json();
  }
  return response.text();
};
